<template>
<div id="app">
    <v-snackbar v-model="snackbartest.visible" auto-height :color="snackbartest.color" :multi-line="snackbartest.mode === 'multi-line'" :timeout="snackbartest.timeout" :top="snackbartest.position === 'top'">
        <v-layout align-center pr-4>
            <v-icon class="pr-3" dark large>{{ snackbartest.icon }}</v-icon>
            <v-layout column>
                <div>
                    <strong>{{ snackbartest.title }}</strong>
                </div>
                <div>{{ snackbartest.text }}</div>
            </v-layout>
        </v-layout>
        <v-btn v-if="snackbartest.timeout === 0" icon @click="snackbartest.visible = false">
            <v-icon>clear</v-icon>
        </v-btn>
    </v-snackbar>
    <v-row>

        <v-col cols="5" lg="2">
            <v-select :items="listdepartement2" @change="departemenSearch2" v-model="selectedepartement2" dense item-value="id" required outlined label="Liste des départements">
                <template slot="selection" slot-scope="data">
                    {{ data.item.libelle }}
                </template>
                <template slot="item" slot-scope="data">
                    {{ data.item.libelle }}
                </template>
            </v-select>
        </v-col>
        <v-col lg="2" cols="5">
            <v-text-field v-model="libelle_recherche" label="Recherche communes" dense outlined></v-text-field>

        </v-col>
        <v-col>
            <v-btn color="primary" dark class="mb-2" @click="recherche()">
                Recherche
            </v-btn>

        </v-col>

        <v-col>
            <v-btn color="green" dark class="mb-2" @click="Actulisation()">
                Actualiser
            </v-btn>

        </v-col>

    </v-row>

    <v-data-table :headers="headers" :items="list" sort-by="code" class="elevation-1">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>LISTE DES COMMUNE PAR PAYS</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px">

                    <v-snackbar v-model="snackbar" :multi-line="multiLine" color="primary">
                        {{ text }}

                        <template v-slot:action="{ attrs }">
                            <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                                Fermer
                            </v-btn>
                        </template>
                    </v-snackbar>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="nouveau()">
                            Nouveau
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-form ref="form" v-model="valid" lazy-validation>

                                    <v-select :items="listpays" v-model="selectepays" dense item-value="id" :rules="[v => !!v || 'Veuillez selectionnez le pays']" required outlined label="Liste des pays">
                                        <template slot="selection" slot-scope="data">
                                            {{ data.item.code_pays }} - {{ data.item.libelle }}
                                        </template>
                                        <template slot="item" slot-scope="data">
                                            {{ data.item.code_pays }} - {{ data.item.libelle }}
                                        </template>
                                    </v-select>
                                    <v-text-field v-model="libelle" label="Libelle departement *" dense outlined>
                                    </v-text-field>

                                </v-form>

                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialog=false">
                                Fermer
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="save">
                                Enregistrer
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                        <v-card-title class="text-h5">Voullez vous vraiment supprimer?</v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialogDelete=false">Annuller</v-btn>
                            <v-btn color="blue darken-1" text @click="supdata()">Confirmer</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
       

        <template v-slot:[`item.actions`]="{ item }">
            <v-icon color="teal" small class="mr-2" @click="editItem(item)">
                mdi-pencil
            </v-icon>
            <v-icon color="red" small @click="deleteItem(item)">
                mdi-delete
            </v-icon>
        </template>
        <template v-slot:no-data>
            <v-btn color="primary" @click="Actulisation">
                Actualiser la liste
            </v-btn>
        </template>
    </v-data-table>

</div>
</template>

<script>
import api from '@/serviceApi/apiService'

export default {
    data: () => ({
        multiLine: true,
        snackbar: false,
        snackbars: false,
        text: `message`,
        textsnackbar: 'messages',
        snackbartest: false,
        text: `message`,
        textsnackbar: 'messages',
        dialog: false,
        dialogDelete: false,
        textetat: false,
        headers: [{
                text: 'ID',
                value: 'id',
                divider: true,
                width: '5%'
            },
            {
                text: 'libelle commune',
                value: 'libelle',
                divider: true,
                width: '50%'
            },
            {
                text: 'libelle departement',
                value: 'departement.libelle',
                divider: true,
                width: '30%'
            },
            {
                text: 'Actions',
                value: 'actions',
                sortable: false,
                divider: true,
                width: '10%'
            },
        ],
        list: [],
        list2: [],
        listpays: [],
        listpays2: [],
        listpays3: [],

        listdepartement: [],
        listdepartement2: [],
        listdepartement3: [],

        libelle: '',
        libelle_recherche: '',

        selectepays: '',
        selectepays2: '',

        selectedepartement2: '',
        selectedepartement: '',

        fichier_json: null,
        id: '0',

        textetat: false,
        formTitle: "Formulaire d'enregistrement",

    }),
    methods: {
        //=====Afficher tout=======

        readAll: async function () {
            let fd = new FormData();
            const data = await api.createUpdatedata('admin-backoffice/all-commune', fd);
            this.list = data;
            this.list2 = data;
        },
        readAllglobale: async function () {
            const data = await api.readAll('admin-backoffice/globale-localisation');
            this.listpays = data.pays;
            this.listpays2 = data.pays;
            this.listdepartement3 = data.departement;
        },

        readAllrecherche: async function () {
                console.log(this.libelle_recherche);
                console.log(this.selectedepartement2);
       
           let fd = new FormData();
            fd.append('libelle', this.libelle_recherche);
            fd.append('id_departements', this.selectedepartement2);
            const data = await api.createUpdatedata('parametre/all-commune-recherche', fd);
             this.list = data.list;
            this.list2 = data.list;
        },

        createUpdatedata: async function () {
            let fd = new FormData();
            fd.append('libelle', this.libelle);
            fd.append('id_departements', this.selectedepartement);

            if (this.textetat) {
                
                const res = await api.createUpdatedata('parametre/update-departement/' + this.id, fd);
                if (res.status == 200) {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };
                    this.dialog = false;
                } else {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };

                }

            } else {
                const res = await api.createUpdatedata('parametre/add-departement', fd);
                if (res.status == 200) {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };

                } else {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "red",
                        icon: "mdi-alert",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Erreur",
                        text: this.textmessage,
                        visible: true
                    };

                }

            }

            this.clear();
            this.readAll();

        },
        delete: async function () {
            console.log(this.id);
            let fd = new FormData();
            fd.append('id', this.id);
            const res = await api.createUpdatedata('parametre/delete', fd);

            this.readAll();
            this.dialogDelete = false;
        },
        recherche() {
            this.readAllrecherche();
           // this.readAllpays();
        },
       
        Actulisation() {
            this.readAll();
            this.readAllglobale();
            this.selectepays2='0';
            this.selectedepartement2='0';
            this.libelle_recherche='';
        },

        supdata() {
            this.delete();

        },
        save() {
            if (this.code_pays == '' || this.libelle == '') {
                this.textmessage = "Veuillez remplir les champs obligatoires";
                this.snackbartest = {
                    color: "red",
                    icon: "mdi-alert",
                    mode: "multi-line",
                    position: "top",
                    timeout: 2500,
                    title: "Erreur",
                    text: this.textmessage,
                    visible: true
                };
                return;
            }
            this.createUpdatedata();

        },
        nouveau() {

            this.clear();
            this.dialog = true;
        },
        editItem(item) {
            this.textetat = true;
            this.id = item.id;
            this.libelle = item.libelle;
            this.selectepays = this.id_pays;
            this.dialog = true;
        },

        deleteItem(item) {
            this.id = item.id;
            this.dialogDelete = true;

        },

        clear() {
            this.libelle = '';
            this.code_pays = '';
            this.textetat = false;
        },

        paysSearch2() {
            //this.list=[];
            this.listdepartement2 = [];
          //  this.list = this.list2.filter(this.filtrerParID);
            this.listdepartement2 = this.listdepartement3.filter(this.filtrerParID_departement2);
        },

        paysSearch() {
            this.listdepartement2 = [];
            this.listdepartement2 = this.listpays2.departements.filter(this.filtrerParID);
        },

        departemenSearch2() {
            this.list=[];
            this.list = this.list2.filter(this.filtrerParID);
        },

        filtrerParID_departement2: function (obj) {
            if (obj.id_pays == this.selectepays2) {
                return true;
            } else {
                return false;
            }
        },
      filtrerParID_departement: function (obj) {
            if (obj.id_pays == this.selectepays) {
                return true;
            } else {
                return false;
            }
        },

        
        filtrerParID: function (obj) {
            if (obj.id_departements == this.selectedepartement2) {

                return true;
            } else {
                return false;
            }
        },


     
    },

    mounted() {
        this.readAll();
        this.readAllglobale();

    },

}
</script>

<style lang="scss" scoped>
.app-content-wrapper {
    position: relative;
    display: flex;
    overflow: hidden;
    height: calc(100vh - 200px);

    .app-overlay {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.4);
        display: none;
        z-index: 2;
        transition: all 0.3s ease-in;

        @media only screen and (max-width: 959px) {
            &.open {
                display: block;
                transition: all 0.3s ease-in;
            }
        }
    }

    .app-sidebar {
        width: 280px;
        position: relative;
        transition: all 0.3s ease-in;

        @media only screen and (max-width: 959px) {
            position: absolute;
            left: -340px;
            z-index: 5;
            height: calc(100vh - 120px);
            transition: all 0.3s ease-in;

            &.open {
                left: 0;
            }
        }

        .app-sidebar-header {
            padding: 0.75rem 1.25rem;
            margin-bottom: 0;
            // border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }

        .app-sidebar-body {
            // overflow-y: scroll;
            // height: 100%;
            padding-top: 3.375rem;
            width: 280px;
        }
    }

    .app-content {
        width: calc(100% - 280px);

        @media only screen and (max-width: 959px) {
            width: 100%;
        }

        // margin-left: 30px;
        .app-header {
            padding: 0.75rem 1.25rem;
            margin-bottom: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }

        .app-body {
            flex: 1 1 auto;
            padding-top: 3.375rem;
            // overflow-y: scroll;
            height: calc(100% - 130px);
        }

        .app-footer {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 130px;
        }
    }
}

.app-icon {
    display: none;

    @media only screen and (max-width: 959px) {
        display: block;
    }
}

.eg-filemanager {
    background-color: #fff;
}

.filemanager-content {
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
}

.apps-wrapper {
    &.--filemanager {
        .nested-sidebar-toggle {
            @media (min-width: 959px) {
                display: none;
            }
        }
    }
}
</style>
